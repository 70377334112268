import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';

export default function SeoNext({
	titleInfo,
	descriptionInfo,
	noindexInfo,
	ogTypeInfo,
	ogTitleInfo,
	ogDescriptionInfo,
	ogImageInfo,
	ogImageWidthInfo,
	ogImageHeightInfo,
	ogImageAltInfo,
	ogImageTypeInfo,
	twitterCreatorInfo,
}) {
	let url = '';
	if (typeof window !== 'undefined') {
		const { location } = window;
		const { href } = location;

		url = href;
	}

	const canonical = url;
	const ogUrl = url || NextSeo.SITE_URL;

	return (
		<NextSeo
			noindex={noindexInfo}
			title={titleInfo}
			description={descriptionInfo}
			canonical={canonical}
			openGraph={{
				type: ogTypeInfo,
				locale: SeoNext.SITE_LOCALE,
				url: ogUrl,
				title: ogTitleInfo,
				description: ogDescriptionInfo,
				site_name: SeoNext.META_TITLE,
				images: [
					{
						url: ogImageInfo,
						width: ogImageWidthInfo,
						height: ogImageHeightInfo,
						alt: ogImageAltInfo,
						type: ogImageTypeInfo,
					},
				],
			}}
			twitter={{
				handle: twitterCreatorInfo,
				site: NextSeo.META_HANDLE,
				cardType: 'summary_large_image',
			}}
		/>
	);
}

// eslint-disable-next-line max-len
SeoNext.META_DESCRIPTION = 'Flavour Network TV – The best easy chicken and dinner recipes, plus how to cook and recipes for cookies, bread, pasta and chili.';
SeoNext.META_TITLE = 'Flavour Network';
SeoNext.META_HANDLE = '@flavournetwork.ca';
SeoNext.SITE_URL = 'https://flavournetwork.ca/';
SeoNext.SITE_LOCALE = 'en_US';

SeoNext.defaultProps = {
	titleInfo: SeoNext.META_TITLE,
	descriptionInfo: SeoNext.META_DESCRIPTION,
	noindexInfo: false,
	ogTitleInfo: SeoNext.META_TITLE,
	ogTypeInfo: 'website',
	ogDescriptionInfo: SeoNext.META_DESCRIPTION,
	ogImageAltInfo: SeoNext.META_TITLE,
	ogImageInfo: '',
	ogImageWidthInfo: '',
	ogImageHeightInfo: '',
	ogImageTypeInfo: 'image/jpeg',
	twitterCreatorInfo: SeoNext.META_HANDLE,
};

SeoNext.propTypes = {
	titleInfo: PropTypes.string,
	descriptionInfo: PropTypes.string,
	noindexInfo: PropTypes.bool,
	ogTitleInfo: PropTypes.string,
	ogTypeInfo: PropTypes.string,
	ogDescriptionInfo: PropTypes.string,
	ogImageInfo: PropTypes.string,
	ogImageWidthInfo: PropTypes.string,
	ogImageHeightInfo: PropTypes.string,
	ogImageAltInfo: PropTypes.string,
	ogImageTypeInfo: PropTypes.string,
	twitterCreatorInfo: PropTypes.string,
};
