import Head from 'next/head';

import { useContext } from 'react';
import SeoNext from '../components/Seo/SeoNext';
import AppContext from '../context/AppContext';

export default function Custom404() {
	const { hostUrl } = useContext(AppContext);

	let sections = '';
	if (typeof window !== 'undefined') {
		const { pathname } = window.location;
		sections = pathname.split('/');
	}

	const urlTemplate = `${hostUrl}search?q={search_term_string}`;

	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: `
						{
							"@context": "https://schema.org",
							"@id": "${hostUrl}#website",
							"@type": "WebSite",
							"url": "${hostUrl}",
							"description": "Page Not Found - Flavour Network",
							"potentialAction": {
								"@type": "SearchAction",
								"target": {
									"@type": "EntryPoint",
									"urlTemplate": "${urlTemplate}"
								},
								"query-input": "required name=search_term_string"
							}
						}`,
					}}
				/>
			</Head>
			<SeoNext
				noindexInfo
				titleInfo="Page Not Found - Flavour Network"
				descriptionInfo="Page Not Found - Flavour Network"
				ogTitleInfo="Page Not Found"
				ogDescriptionInfo="Page Not Found"
			/>
			<div className="four-zero-four-container">
				<div className="images-container">
					<img
						className="img-top"
						src="/images/404_oneline.svg"
						alt="Oops Broken"
					/>
					<img
						className="img-bottom"
						src="/images/404.svg"
						alt="Oops Broken"
					/>
				</div>
				<h3>
					The page you were looking for appears to have been moved,
					deleted or does not exist.
				</h3>
				<a href="/" title="Go to Homepage">
					GO TO HOMEPAGE
				</a>
			</div>
		</>
	);
}
